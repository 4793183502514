import myhttp from './http'

//用户列表
export function userList(procuratorateid,page, pageSize) {
    return myhttp({
        url: "/back/selectBackuserList",
        method: "POST",
        params: { procuratorateid,page, pageSize }
    });
}


//用户注册
export function registered(info) {
    return myhttp({
        url: "/back/insertBackuserInfo",
        method: "POST",
        data: info
    });
}


//根据id修改一个用户信息
export function updataUserById(info) {
    return myhttp({
        url: "/back/updateBackuserInfo",
        method: "POST",
        data: info
    });
}

//根据id修改一个用户信息
export function selectUserById(id) {
    return myhttp({
        url: "/back/selectBackuserById",
        method: "POST",
        params: { id }
    });
}




//根据id删除一个用户
export function deleteUserById(id) {
    return myhttp({
        url: "/back/deleteBackuserInfo",
        method: "POST",
        params: { id }
    });
}
//获取检察院信息
export function getProInfoList(page,pageSize) {
    return myhttp({
        url: "/pro/selectProInfoList",
        method: "POST",
        data: { page,pageSize }
    });
}
//修改检察院信息
export function updateProInfo(procuratorate) {
    return myhttp({
        url: "/pro/updateFileInfo",
        method: "POST",
        data: procuratorate
    });
}
//获取所有部门信息
export function getDepartmentByPage(page,pageSize,procuratorateid) {
    return myhttp({
        url: "/dep/selectDepInfoByProId",
        method: "POST",
        params: { page,pageSize,procuratorateid }
    });
}
// 添加一个部门信息
export function insertLocation(depname, procuratorateid) {
    return myhttp({
        url: "/dep/inserDepInfo",
        method: "POST",
        data: { depname, procuratorateid }
    });

}
//删除一个部门信息
// export function deleteLocation(id) {
//     return myhttp({
//         url: "/system/deleteLocation",
//         method: "GET",
//         params: { id }
//     });

// }

//修改一个部门信息
export function updateLocation(id, depname) {
    return myhttp({
        url: "/dep/updateDepInfo",
        method: "POST",
        data: { id, depname }
    });
}

//查询专业领域接口
export function getAllSpecialty(page, pageSize, professional) {
    return myhttp({
        url: "/fild/selectFildInfotwo",
        method: "POST",
        params: { page, pageSize, professional }
    });
}


// 添加一个专业领域
export function insertSpecialty(professional) {
    return myhttp({
        url: "/fild/insertFileInfo",
        method: "POST",
        data: { professional }
    });

}
//删除一个专业领域
export function deleteSpecialty(id) {
    return myhttp({
        url: "/fild/deleteFileInfoById",
        method: "POST",
        params: { id }
    });

}

//修改一个专业领域
export function updateSpecialty(id, professional) {
    return myhttp({
        url: "/fild/updateFileInfo",
        method: "POST",
        data: { id, professional }
    });
}

//上传文件
export function upload(file) {
    return myhttp({
        url: "/system/upload",
        method: "POST",
        data: {file }
    });
}

//查询案件类型
export function getAllCase(page, pageSize, casename) {
    return myhttp({
        url: "/case/selectCaseInfotwo",
        method: "POST",
        params: { page, pageSize, casename }
    });
}

//删除一个案件类型
export function deleteCase(id) {
    return myhttp({
        url: "/case/deleteCaseInfo",
        method: "POST",
        params: { id }
    });
}

//修改一个案件类型
export function updateCase(id, casename) {
    return myhttp({
        url: "/case/updateCaseInfo",
        method: "POST",
        data: { id, casename }
    });
}

//添加一个案件类型
export function insertCase(casename) {
    return myhttp({
        url: "/case/insertCaseInfo",
        method: "POST",
        data: { casename }
    });
}