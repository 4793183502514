// const devBaseURL = "http://192.168.1.128:9999/";// 开发环境
// const proBaseURL = "http://192.168.1.128:9999/";// 生产环境
// const devBaseURL = "http://192.168.1.101:9999/";// 开发环境
// const proBaseURL = "http://192.168.1.101:9999/";// 生产环境

const devBaseURL = "https://tltz.api.lziit.cn";// 开发环境
const proBaseURL = "https://tltz.api.lziit.cn";// 生产环境






// 判断当前的环境
export const BASE_URL = process.env.NODE_ENV === "development" ? devBaseURL : proBaseURL;
// 请求超时
export const TIMEOUT = 5000;

